import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth } from '../../components/Auth';
import { useLoading } from '../../components/Loading';
import DealDetailPresentational from './DealDetailPresentational';
import fetchDeal from '../../lib/fetch/deal';
import saveDeal from '../../lib/fetch/saveDeal';
import deleteDeal from '../../lib/fetch/deleteDeal';
import { fetchActivities, saveActivity, deleteActivity } from '../../lib/fetch/activities';
import createActivity from '../../lib/fetch/createActivity';
import { prepareActivity } from '../../lib/helpers/activities';

function DealDetail({ match, history }) {
  const { params: { id } = {} } = match;
  const [deal, setDeal] = useState({});
  const [activitiesData, setActivitiesData] = useState([]);
  const [t] = useTranslation('DEAL_DETAIL');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    const fetch = async () => {
      getDeal();
      fetchListActivities();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeal = async () => {
    const { error, data } = await fetchDeal({ id, token });

    if (error) {
      setDeal({});
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    setDeal(data);
  }

  const fetchListActivities = async () => {
    const { error, data } = await fetchActivities({ dealId: id, token, limit: 10000000 });

    if (error) {
      setActivitiesData([]);
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    setActivitiesData(data);
  }

  const onSave = async (values) => {
    const body = { ...deal, ...values };
    setDeal(body);
    const { error, data } = await saveDeal({ token, data: body });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setDeal(data);
  }

  const onDelete = async () => {
    const { id } = deal;

    showLoading()
    const { error } = await deleteDeal({ token, id });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    history.push('/deals');
    alert.success(t('COMMON:DELETE_SUCCESS'));
  }

  const onSaveActivity = async (values, type, activityId) => {
    const data = prepareActivity({ activity: values, type, deal_id: id })

    const { error } = activityId ? await saveActivity({ token, data, id: activityId }) : await createActivity({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'));
    fetchListActivities();
  }

  const onDeleteActivity = async (activityId) => {
    showLoading()
    const { error } = await deleteActivity({ id: activityId, token })
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    alert.success(t('COMMON:DELETE_SUCCESS'));
    fetchListActivities();
  }

  return (
    <DealDetailPresentational
      match={match}
      deal={deal}
      activitiesData={activitiesData}
      onSave={onSave}
      onDelete={onDelete}
      onSaveActivity={onSaveActivity}
      onDeleteActivity={onDeleteActivity}
      getDeal={getDeal}
    />
  );
}

export default withRouter(DealDetail);
