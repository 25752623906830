import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Modal, ModalBody, Spinner } from 'reactstrap';
import ModalConfirm from './../ModalConfirm';
import trashIcon from '../../images/icn-trash.svg';
import './Attachments.scss'

const AttachmentsPresentational = ({ attachments, onChangeInputFile, uploading, onDownload, onDelete }) => {
  const [t] = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-6">
          <p>{t('COMMON:ATTACHMENTS')}:</p>
        </div>
        <div className="col-6 text-right">
          <input
            id="attachments"
            type="file"
            className="attachment-input-file"
            onChange={onChangeInputFile}
            onClick={(event) => event.currentTarget.value = null}
          />
          <Label className="btn btn-add-attachment" htmlFor="attachments">
            {t('COMMON:ADD_ATTACHMENT')}
          </Label>
        </div>
      </div>
      {
        !attachments || attachments.length === 0 ?
          <div><small>{t('DEAL_DETAIL:NO_ATTACHMENTS')}</small></div>
          :
          attachments.map(({ file_name, id }, key) => (
            <div key={key} className="p-1">
              <span onClick={() => onDownload(id)} className="attachment-link">
                {file_name}
              </span>
              <ModalConfirm
                className="attachment-modal-confirm"
                text={t('DEAL_DETAIL:ATTACHMENT_DELETE_REQUEST')}
                onConfirm={() => onDelete(id)}
              >
                <div className="attachment-btn-trash">
                  <img src={trashIcon} alt="" />
                </div>
              </ModalConfirm>
            </div>
          ))
      }

      <Modal
        isOpen={uploading}
        backdrop="static"
        keyboard={false}
        centered={true}
        contentClassName="attachment-content-modal"
      >
        <ModalBody>
          <div className="attachments-modal-spinner">
            <Spinner />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AttachmentsPresentational;