export const COST_CENTERS = [
  {
    name: 'development',
    color: '#6689A1'
  },
  {
    name: 'prototyping',
    color: '#D8DC6A'
  },
  {
    name: 'fee',
    color: '#EB8258'
  },
  {
    name: 'consulting',
    color: '#995D81'
  }
]